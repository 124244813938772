



























import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'OleMiss116Post2_Q2part5',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      attachments: [] as File[],
    };
  },
});
